@media only screen and (max-width: 600px) {
  .content {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
.content {
  padding: 70px 10px;
  padding-left: 250px;
  font-weight: 300 !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Sarabun", sans-serif;
  font-weight: 300;
}

div,
td,
h1,
h2,
h3,
h4,
h5,
label,
h6 {
  font-family: "Sarabun", sans-serif !important;
  font-weight: 300;
}
th {
  font-family: "Sarabun", sans-serif !important;
  font-weight: 700 !important;
}
option,
select,
textarea,
input {
  font-family: "Sarabun", sans-serif !important;
  font-weight: 300;
}
